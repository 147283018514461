.section01_wrap {
  position: relative;
  width: 100%;
  height: 10.71rem;
  background: url(../../../assets/section01_bgs.jpg) no-repeat center;
  background-size: cover;
  overflow: hidden;
  user-select: none;
}

.video_pop_wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 99;
}
.video_inner {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 8.83rem;
  height: 4.97rem;
  transform: translate(-50%, -50%);
}
.close_pop {
  position: absolute;
  right: -0.3rem;
  top: -0.3rem;
  width: 0.22rem;
  height: 0.23rem;
  background-size: contain;
  background: url(../../../assets/close_media_icon.png) no-repeat center;
  background-size: contain;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    transform: rotate(180deg);
  }
}
.down_info_wrap {
  width: 0.4rem;
  height: 0.5rem;
  // background-color: pink;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin: auto;
  margin-top: 0.38rem;
}
.down_info_01 {
  width: 0.27rem;
  height: 0.17rem;
  background: url(../../../assets/opcity01.png) no-repeat center;
  background-size: contain;
  animation: alls ease-in-out 1.5s infinite;
}

.down_info_02 {
  width: 0.27rem;
  height: 0.17rem;
  background: url(../../../assets/opcity01.png) no-repeat center;
  background-size: contain;
  animation: allsX ease-in-out 1.5s infinite;
}

.down_info_03 {
  width: 0.27rem;
  height: 0.17rem;
  background: url(../../../assets/opcity01.png) no-repeat center;
  background-size: contain;
  animation: allsXs ease-in-out 1.5s infinite;
}
@keyframes alls {
  0% {
    background: url(../../../assets/opcity01.png) no-repeat center;
    background-size: contain;
  }

  30% {
    background: url(../../../assets/opcity03.png) no-repeat center;
    background-size: contain;
  }
}

@keyframes allsX {
  0% {
    background: url(../../../assets/opcity01.png) no-repeat center;
    background-size: contain;
  }

  30% {
    background: url(../../../assets/opcity02.png) no-repeat center;
    background-size: contain;
  }

  60% {
    background: url(../../../assets/opcity03.png) no-repeat center;
    background-size: contain;
  }
}

@keyframes allsXs {
  0% {
    background: url(../../../assets/opcity01.png) no-repeat center;
    background-size: contain;
  }

  30% {
    background: url(../../../assets/opcity01.png) no-repeat center;
    background-size: contain;
  }

  60% {
    background: url(../../../assets/opcity02.png) no-repeat center;
    background-size: contain;
  }

  100% {
    background: url(../../../assets/opcity03.png) no-repeat center;
    background-size: contain;
  }
}
.play_video_icon {
  position: absolute;
  left: 50%;
  bottom: 3.8rem;
  width: 1.22rem;
  height: 1.22rem;
  margin-left: -0.61rem;
  background: url(../../../assets/video_play_icon.png) no-repeat center;
  background-size: contain;
  cursor: pointer;
}
.circle {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 1rem;
  height: 1rem;
  margin-left: -0.5rem;
  margin-top: -0.5rem;
  border: 0.04rem solid #febb2a;
  opacity: 0.5;
  border-radius: 50%;
}
.circle-1 {
  animation: zoomOut 2s ease-in-out 0s infinite;
}
.circle-2 {
  animation: zoomOut 2s ease-in-out 0.2s infinite;
}
.circle-3 {
  animation: zoomOut 2s ease-in-out 0.4s infinite;
}
.day-countdown-wrap {
  position: absolute;
  left: 0;
  bottom: 2rem;
  width: 100%;
  height: 0.6rem;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  color: #fff;
  font-family: pageHome;
  font-style: italic;
  font-size: 0.42rem;
  text-shadow: 0 0 0.1rem #3f0161;

  .day-num {
    font-size: 0.6rem;
    line-height: 0.63rem;
    margin-right: 0.1rem;
    vertical-align: bottom;
  }
}
.pre-regist-btn {
  display: block;
  width: 2.77rem;
  height: 0.74rem;
  margin: 0 auto;
  margin-top: 0.2rem;
  background: url('../../../assets/pre_regist_en.png') no-repeat;
  background-size: contain;
  transition: all 0.55s cubic-bezier(0.19, 1, 0.22, 1);
  &:hover {
    transform: scale(1.05);
  }
}

.download-btns {
  position: absolute;
  left: 0;
  bottom: 0.8rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.downlink {
  height: 0.64rem;
  margin: 0 0.1rem;
  img {
    height: 100%;
    width: auto;
    display: block;
  }

  &.android {
    position: absolute;
    right: 0.4rem;
    bottom: 1rem;
    height: 0.64rem;
    z-index: 1;
  }

  &.ios {
    position: absolute;
    right: 0.4rem;
    bottom: 2rem;
    height: 0.7rem;
    width: 2.09rem;
  }
}

@keyframes zoomOut {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  50% {
    transform: scale(2);
    opacity: 0;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.glow-on-hover {
  position: relative;
  z-index: 0;
}

.glow-on-hover:before {
  content: '';
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

.glow-on-hover:active:after {
  background: transparent;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.open-slide {
  position: fixed;
  right: 0;
  top: 2.5rem;
  width: 2.72rem;
  height: 2.63rem;
  background: url('../../../assets/alr_o.png') no-repeat center;
  background-size: cover;
  z-index: 3;
  transition: all 0.3s ease-out;
  .words {
    position: absolute;
    left: 50%;
    bottom: 0.1rem;
    width: 2.5rem;
    height: 0.4rem;
    transform: translateX(-50%);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-family: pageHome;
    font-size: 0.26rem;
    white-space: nowrap;
  }
  .jump {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    cursor: pointer;
  }
  .clo {
    position: absolute;
    left: 0.1rem;
    top: 1.4rem;
    width: 0.4rem;
    height: 0.5rem;
    z-index: 2;
    cursor: pointer;
  }
}
.hide-slide {
  right: -2.8rem;
}
.close-slide {
  position: fixed;
  right: 0;
  top: 3.6rem;
  width: 0.98rem;
  height: 0.8rem;
  background: url('../../../assets/alr_c.png') no-repeat center;
  background-size: cover;
  z-index: 3;
  transition: all 0.3s ease-out;
  cursor: pointer;
}
.hide-close-slide {
  right: -1.15rem;
}
