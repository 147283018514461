.incline_hover {
  background-color: #a35dca;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: pageHome;
  cursor: pointer;
  color: white;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  position: relative;
  overflow: hidden;
}
.incline_hover span {
  position: relative;
  z-index: 2;
}
.incline_hover::before {
  content: '';
  position: absolute;
  left: -5.79rem;
  top: 0;
  width: 150%;
  height: 150%;
  background-image: linear-gradient(45deg, black, black);
  -webkit-transform: skewx(-36deg);
  transition: 0.3s;
}
.incline_hover:hover::before {
  transition: all 0.3s ease;
  left: -1rem;
}

// 五边形

.incline_hovers {
  background-color: #a35dca;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: pageHome;
  cursor: pointer;

  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  position: relative;
  overflow: hidden;
}
.incline_hovers span {
  position: relative;
  z-index: 2;
}
.incline_hovers::before {
  content: '';
  position: absolute;
  left: -5.79rem;
  top: 0;
  width: 250%;
  height: 150%;
  background-image: linear-gradient(45deg, #621d8c, #621d8c);
  -webkit-transform: skewx(-36deg);
  transition: 0.3s;
}
.incline_hovers:hover::before {
  transition: all 0.3s ease;
  left: -1rem;
}

// 大的
.big_incline_hover {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: pageHome;
  cursor: pointer;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  position: relative;
  overflow: hidden;
}
.big_incline_hover span {
  position: relative;
  z-index: 2;
}
.big_incline_hover::before {
  content: '';
  position: absolute;
  left: -210%;
  top: 0;
  width: 200%;
  height: 100%;
  background-color: #621d8c;
  transform: skewx(-36deg);
  transition: 0.3s;
}
.big_incline_hover:hover::before {
  transition: all 0.3s ease;
  left: -1rem;
}
