.pc-header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 1.5rem;
  background: url('../../assets/pc_header.png') no-repeat;
  background-position: center top;
  background-size: contain;
  z-index: 10;
  font-family: pageHome;
  font-style: italic;
}
.left-area {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  height: 0.8rem;
  align-items: center;
}
.right-area {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  height: 0.8rem;
  align-items: center;
  justify-content: flex-end;
}
.left-area div,
.right-area div {
  font-size: 0.24rem;
  color: #fff;
  cursor: pointer;
}
.left-area div {
  margin-left: 0.3rem;
}
.left-area > .hover-nav {
  margin-left: 0.3rem;
}
.right-area div {
  margin-right: 0.3rem;
}
.center-logo {
  position: absolute;
  left: 50%;
  top: 0.15rem;
  width: 1.42rem;
  height: 0.92rem;
  margin-left: -0.71rem;
  background: url('../../assets/logo.png') no-repeat center;
  background-size: contain;
  cursor: pointer;
}
.hover-nav {
  position: relative;
  text-decoration: none;
  color: #fff;
  font-size: 0.24rem;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -0.05rem;
    width: 0;
    height: 0.02rem;
    background: linear-gradient(to right, rgba(100, 200, 200, 1), rgba(100, 200, 200, 1)),
      linear-gradient(to right, rgba(255, 0, 0, 1), rgba(255, 0, 180, 1), rgba(0, 100, 200, 1));
    background-size: 0 0.03rem, 100% 0.03rem;
    background-position: 100% 100%, 0 100%;
    background-repeat: no-repeat;
    transition: all 0.3s ease-in-out;
  }
  &:hover::after {
    width: 100%;
    transition: all 0.3s ease-in-out;
  }
}
.sub-nav {
  position: relative;
  padding: 0.25rem 0.2rem 0.25rem 0;
  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    width: 0;
    height: 0;
    border-left: 0.06rem solid transparent;
    border-right: 0.06rem solid transparent;
    border-bottom: 0rem solid transparent;
    border-top: 0.06rem solid #fff;
    transition: all 0.3s;
  }

  .sub-nav-wrap {
    position: absolute;
    left: 0;
    top: 0.75rem;
    width: auto;
    margin-left: 0;
    padding: 0.1rem;
    background: rgba(0, 0, 0, 0.8);
    transform-origin: left top;
    transform: scale(0);
    transition: all 0.3s ease-in-out;
    opacity: 0;
    div {
      white-space: nowrap;
      margin: 0.1rem 0;
    }
  }

  &:hover .sub-nav-wrap {
    transform-origin: left top;
    transform: scale(1);
    transition: all 0.3s ease-in-out;
    opacity: 1;
  }

  &:hover::after {
    transform: rotate(180deg);
  }
}

.sub-2nd-nav {
  position: relative;
  padding-right: 0.2rem;

  &::after {
    position: absolute;
    top: 50%;
    right: 0.06rem;
    margin-top: -0.03rem;
    width: 0;
    height: 0;
    border-top: 0.06rem solid transparent;
    border-right: 0rem solid transparent;
    border-bottom: 0.06rem solid transparent;
    border-left: 0.06rem solid #fff;
    content: '';
    transition: transform 0.3s;
  }

  &:hover::after {
    transform: rotate(180deg);
  }

  .sub-2nd-nav-wrap {
    position: absolute;
    top: -100%;
    left: 100%;
    margin-left: 0;
    padding: 0.1rem;
    width: auto;
    background: rgba(0, 0, 0, 0.8);
    opacity: 0;
    transition: all 0.3s ease-in-out;
    transform: scale(0);
    transform-origin: left top;

    div {
      margin: 0.1rem 0;
      white-space: nowrap;
    }
  }

  &:hover .sub-2nd-nav-wrap {
    opacity: 1;
    transition: all 0.3s ease-in-out;
    transform: scale(1);
    transform-origin: left top;
  }
}

.language-nav {
  padding-left: 0.4rem;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 0.24rem;
    height: 0.24rem;
    margin-top: -0.12rem;
    background: url('../../assets/head_language.png') no-repeat center;
    background-size: contain;
  }
  .sub-nav-wrap {
    margin-right: 0;
    width: 2.6rem;
    padding: 0;
    left: 50%;
    margin-left: -1.3rem;
  }
  .center-text {
    text-align: center;
    cursor: default;
    span {
      padding: 0 0.1rem;
      cursor: pointer;
    }
  }
}

.img-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-shadow: 0 0 0.02rem #777;
  font-size: 0.24rem;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  &:hover {
    transition: all 0.3s ease-in-out;
    transform: scale(1.1);
  }
}

.head-login {
  width: 1.25rem;
  height: 0.58rem;
  background: url('../../assets/head_login.png') no-repeat center;
  background-size: contain;
}
.head-download {
  width: 1.77rem;
  height: 0.58rem;
  margin-right: 0.3rem;
  background: url('../../assets/head_download.png') no-repeat center;
  background-size: contain;
  cursor: pointer;
}
.head-regressive {
  width: 1.77rem;
  height: 0.58rem;
  margin-right: 0.3rem;
  background: url('../../assets/head_regressive.png') no-repeat center;
  background-size: contain;
  cursor: pointer;
  white-space: nowrap;
}

.download {
  width: 7.71rem;
  height: 5.43rem;
  background: url('../../assets/modal_bg.png') no-repeat center;
  background-size: contain;
  overflow: hidden;
}
.down-title {
  margin-top: 0.5rem;
  font-size: 0.48rem;
  height: 0.95rem;
  color: #fff;
  line-height: 0.48rem;
  text-align: center;
}
.qrcode {
  position: absolute;
  left: 50%;
  top: 1.95rem;
  width: 1.8rem;
  height: 1.8rem;
  margin-left: -0.9rem;
  background: url('../../assets/snap_down_qr.png') no-repeat center;
  background-size: contain;
}
.down-links {
  position: absolute;
  left: 0;
  bottom: 0.5rem;
  width: 100%;
  margin-top: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.down-a {
  position: relative;
  z-index: 0;
  &::before {
    content: '';
    background: linear-gradient(
      45deg,
      #ff0000,
      #ff7300,
      #fffb00,
      #48ff00,
      #00ffd5,
      #002bff,
      #7a00ff,
      #ff00c8,
      #ff0000
    );
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
    opacity: 0;
  }

  &:hover:before {
    opacity: 1;
  }
  &:nth-child(2) {
    margin: 0 0.15rem;
  }

  a {
    display: block;
  }
}

.google {
  width: 2.1rem;
  height: 0.64rem;
  background: url('../../assets/googleplay_btn.png') no-repeat center;
  background-size: contain;
}
.appstore {
  width: 1.91rem;
  height: 0.64rem;
  background: url('../../assets/appstore_btn.png') no-repeat center;
  background-size: contain;
}
.windows {
  width: 1.9rem;
  height: 0.64rem;
  background: url('../../assets/window_btn.png') no-repeat center;
  background-size: contain;
}
@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.shop-pop {
  width: 4.81rem;
  height: 4.53rem;
  background: url(../../assets/shop-pop-bg.png) no-repeat center / contain;
  font-family: RoBotBold;
  font-style: normal;
  transform: scale(1.2);
  .time {
    font-size: 0.2rem;
    color: #fff;
    position: absolute;
    right: 0.35rem;
    top: 0.75rem;
    span {
      background-color: #4F40C4;
      padding: 0rem 0.03rem;
    }
  }
  .text1 {
    color: #fff;
    font-size: 0.39rem;
    position: absolute;
    // padding: 0rem 0.04rem;
    // background-color: #4F40C4;
    left: 0.2rem;
    top: 2.67rem;
  }
  .text2 {
    color: #fff;
    font-size: 0.2rem;
    background-color: #4F40C4;
    position: absolute;
    padding: 0rem 0.04rem;
    left: 0.2rem;
    top: 3.17rem;
  }
  .btn {
    width: 1.84rem;
    height: 0.59rem;
    background: url(../../assets/shop-pop-btn.png) no-repeat center / contain;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0.32rem;
    display: flex;
    justify-content: center;
    font-size: 0.24rem;
    line-height: 0.36rem;
    cursor: pointer;
  }
}