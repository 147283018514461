:global #root {
  overflow-x: hidden;
}
@font-face {
  font-family: pageHome;
  src: url(../utils/font/CCElephantmenTall_W10_Italic.ttf);
}
@font-face {
  font-family: smallHome;
  src: url(../utils/font/Roboto_Regular.ttf);
}
@font-face {
  font-family: RoBotMedium;
  src: url(../utils/font/Roboto-Medium.ttf);
}
@font-face {
  font-family: RoBotBold;
  src: url(../utils/font/Roboto-Bold.ttf);
}
@font-face {
  font-family: font_ja;
  src: url(../utils/font/font_ja.otf);
}
@font-face {
  font-family: font_tl;
  src: url(../utils/font/font_tl.ttf);
}
@font-face {
  font-family: font_kr_zw;
  src: url(../utils/font/kr_zw.otf);
}
:global #root .ft_kr_zw {
  font-family: font_kr_zw !important;
}
:global #root .ft_italic {
  font-family: pageHome !important;
}
:global #root .ft_ptes {
  font-family: RoBotMedium !important;
}
:global #root .ft_ja {
  font-family: font_ja !important;
}
:global #root .ft_tl {
  font-family: font_tl !important;
}
