.hidebom {
  width: 100%;
  height: 2rem;
  background-color: #5a2487;
}
.section03_wrap {
  width: 100%;
  height: 6.38rem;
  background: url(../../../assets/section03_bg.jpg) no-repeat center;
  background-size: cover;
  overflow: hidden;
  position: relative;
  font-family: pageHome;
  font-style: italic;
}
.sec3-title {
  width: 100%;
  font-size: 0.42rem;
  color: #000;
  text-align: center;
  margin-top: 1rem;
}
.section03_title {
  width: 19.2rem;
  height: 0.84rem;
  text-align: center;
  font-size: 0.9rem;
  color: #5a2487;
  margin-top: 1.34rem;
}
.section03_desc {
  font-size: 0.19rem;
  color: black;
  width: 8.52rem;
  // height: 0.85rem;
  text-align: center;
  margin: auto;
  margin-top: 0.34rem;
  line-height: 0.3rem;
  margin-bottom: 0.3rem;
  span {
    cursor: pointer;
  }
}
.section03_btm {
  width: 6.61rem;
  height: 0.7rem;
  margin: auto;
  margin-top: 0.36rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  input {
    outline: none;
    border: 1px solid black;
    width: 4.51rem;
    height: 0.55rem;
    // font-family: smallHome;
    font-weight: 400;
    font-style: oblique;
    color: #9e9e9e;
  }
  input::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #9e9e9e;
  }
  input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #9e9e9e;
  }
  input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #9e9e9e;
  }
  input:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #9e9e9e;
  }
  .get_email {
    width: 1.79rem;
    height: 0.55rem;
    background-color: #a35dca;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: white;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    position: relative;
    overflow: hidden;
    font-size: 0.24rem;
  }
  .get_email span {
    position: relative;
    z-index: 2;
  }
  .get_email::before {
    content: '';
    position: absolute;
    left: -3.79rem;
    top: 0;
    width: 150%;
    height: 150%;
    background-image: linear-gradient(45deg, black, black);
    -webkit-transform: skewx(-36deg);
    transform: skewx(-36deg);
    transition: 0.3s;
  }
  .get_email:hover::before {
    transition: all 0.3s ease;
    left: -0.3rem;
  }
}

.follow_snap {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 4.62rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  transform: translate(-50%, -50%);
}
.channel_icon_wrap {
  position: relative;
  width: 0.93rem;
  height: 1.08rem;
  background-color: #5a2487;
  clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
  margin: 0 0.1rem 0.1rem 0.1rem;
}
.channel_icon_wraps {
  position: absolute;
  left: 0.037rem;
  top: 0.047rem;
  width: 0.8556rem;
  height: 0.9936rem;
  background-color: #9f6f80;
  clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 0.45rem;
    z-index: 9;
  }
}

.channel_down {
  width: 100%;
  height: 0.59rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.55rem;
  position: absolute;
  left: 0;
  bottom: 0.83rem;
}

.con_tent {
  min-width: 2rem;
  padding: 0 0.2rem;
  height: 0.49rem;
  margin: 0 0.2rem;
  outline: 0.03rem solid #5a2487;
  font-size: 0.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  span {
    text-align: center;
    white-space: nowrap;
  }
  .load_span {
    color: #5a2487;
  }
}

.con_tent:hover {
  span {
    color: white;
    transition: 0.3s ease-in-out;
  }
}
