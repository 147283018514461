.domain_limit_wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 999;
  color: white;
  font-size: 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}
