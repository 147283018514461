.section02_wrap {
  position: relative;
  width: 100%;
  z-index: 2;
}
.empty {
  height: 1px;
}
.news-wrap {
  width: 100%;
  height: 8rem;
  background: url(../../../assets/news_bg.jpg) no-repeat center;
  background-size: cover;
}

.section02_title_wrap {
  width: 12.93rem;
  // height: 1.18rem;
  margin: auto;
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
}

.section02_title {
  font-size: 0.9rem;
  font-family: pageHome;
  font-style: italic;
  color: #5a2487;
  white-space: nowrap;
  transform-origin: left center;
}

.section02_title_wrap_02 {
  width: 10.53rem;
  height: 1.18rem;
  margin: auto;
  margin-top: 1.49rem;
  display: flex;
  justify-content: space-between;
}

.arrows {
  width: auto;
  height: 1.18rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 0.2rem;
  font-style: italic;
  cursor: pointer;
}
.arrows span {
  transition: all 0.3s ease-in-out;
}

.arrows span:nth-child(1) {
  // height: 0.37rem;
  font-size: 0.26rem;
  color: #a35dca;
  font-family: pageHome;
  white-space: nowrap;
  margin-right: 0.1rem;
}

.arrows:hover {
  span:nth-child(1) {
    color: #111;
  }

  span:nth-child(2) {
    width: 0.28rem;
    height: 0.16rem;
    background: url(../../../assets/black_arrow.png) no-repeat center;
    background-size: contain;
  }
}

.arrows span:nth-child(1):hover {
}

.arrows span:nth-child(2) {
  width: 0.28rem;
  height: 0.16rem;
  background: url(../../../assets/arrows.png) no-repeat center;
  background-size: contain;
}

.home_news_wrap {
  width: 12.88rem;
  height: 3.98rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
}

.home_newss {
  width: 4.16rem;
  height: 3.98rem;
  cursor: pointer;
  position: relative;
  background-color: white;
  transition: all 0.3s ease-in-out;
  &:hover {
    box-shadow: 0 0 0.2rem #a35dca;
    transform: scale(1.02);
  }
}

.incline_block {
  position: absolute;
  width: 8rem;
  height: 8rem;
  background-color: black;
  opacity: 0.5;
  left: -8rem;
  top: -5rem;
  display: block;
  transition: 0.1s;
}
// .home_newss:hover .incline_block {
//   position: absolute;
//   width: 8rem;
//   height: 12rem;
//   background-color: #a35dca;
//   opacity: 0.5;
//   left: -8rem;
//   top: -5rem;
//   display: block;
//   animation: funcTranslate linear 0.1s forwards;
// }

.home_newss:hover .home_news_desc {
  color: #5a2487;
}

.home_news_bg {
  position: relative;
  width: 4.16rem;
  height: 2.41rem;
  overflow: hidden;

  img {
    width: 4.16rem;
    height: 2.41rem;

    // transition: 0.5s;
  }
}

@keyframes funcTranslate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(50deg);
    transform-origin: right bottom;
  }
}

.home_news_title {
  width: 3.41rem;
  height: 0.5rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  font-size: 0.18rem;
  color: #a35dca;
  font-family: RoBotMedium;
  margin-left: 0.28rem;
}

.home_news_desc {
  width: 3.5rem;
  height: 0.55rem;
  font-family: RoBotBold;
  font-size: 0.24rem;
  color: #111111;
  margin-left: 0.28rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.home_news_date {
  margin-top: 0.1rem;
  color: #7f7f7f;
  font-size: 0.3rem;
  transform: scale(0.5) translateX(-1.5rem);
}
// superHero
.hero_wrap {
  width: 100%;
  height: 8.86rem;
  position: relative;
  background-color: #5a2487;
}

.hero_left {
  position: absolute;
  left: 2rem;
  top: 50%;
  width: 6rem;
  transform: translateY(-50%);
}

.hero_right {
  position: absolute;
  width: 12rem;
  height: 8.86rem;
  right: 0;
  top: 0;
  background: url(../../../assets/heros.png) no-repeat center;
  background-size: cover;
  perspective: 10rem;
}

.hero_title {
  width: 100%;
  color: white;
  font-size: 1.12rem;
  font-family: pageHome;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  font-style: italic;
  white-space: nowrap;
}

.hero_desc {
  font-size: 0.3rem;
  color: #ffbf2c;
  width: 100%;
  font-style: italic;
  font-family: smallHome;
  text-align: left;
  line-height: 0.4rem;
  margin-top: 0.2rem;
}

.hero_contain {
  width: 100%;
  font-size: 0.19rem;
  color: white;
  font-family: smallHome;
  margin-top: 0.3rem;
  word-break: break-word;
  overflow: hidden;
  line-height: 0.35rem;
  text-overflow: ellipsis;
  display: -webkit-box;
  // -webkit-line-clamp: 6;
  // line-clamp: 6;
  -webkit-box-orient: vertical;
}

// 长度为2的时候兼容样式
.home_news_wrap_2 {
  width: 12.88rem;
  height: 4.98rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.home_newss_2 {
  width: 5.17rem;
  height: 4.95rem;
  cursor: pointer;
  position: relative;
  background-color: white;
  margin-right: 0.2rem;
  transition: all 0.3s ease-in-out;
  &:hover {
    box-shadow: 0 0 0.2rem #a35dca;
    transform: scale(1.02);
  }
}

.incline_block_02 {
  position: absolute;
  width: 8rem;
  height: 8rem;
  background-color: black;
  opacity: 0.5;
  left: -8rem;
  top: -5rem;
  display: block;
  transition: 0.1s;
}

// .home_newss_2:hover .incline_block_02 {
//   position: absolute;
//   width: 8rem;
//   height: 12rem;
//   background-color: #a35dca;
//   opacity: 0.5;
//   left: -7.5rem;
//   top: -5rem;
//   display: block;
//   animation: funcTranslate linear 0.1s forwards;
// }

.home_newss_2:hover .home_news_desc_2 {
  color: #5a2487;
}

.home_news_bg_2 {
  position: relative;
  width: 5.17rem;
  height: 3.02rem;
  overflow: hidden;

  img {
    width: 5.17rem;
    height: 3.02rem;

    // transition: 0.5s;
  }
}

@keyframes funcTranslate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(50deg);
    transform-origin: right bottom;
  }
}

.home_news_title_2 {
  width: 5.71rem;
  height: 0.71rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  font-size: 0.18rem;
  color: #a35dca;
  font-family: RoBotMedium;
  margin-left: 0.28rem;
}

.home_news_desc_2 {
  width: 4.9rem;
  height: 0.55rem;
  font-family: RoBotBold;
  font-size: 0.24rem;
  color: #111111;
  margin-left: 0.28rem;

  // word-break: break-all;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.home_news_date_2 {
  width: 5.71rem;
  color: #7f7f7f;
  font-size: 0.3rem;
  transform: scale(0.5) translateX(-2.3rem);
  line-height: 0.3rem;
}
// 只有一篇新闻
.home_news_01_wrap {
  width: 12rem;
  height: 4.43rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    box-shadow: 0 0 0.2rem #a35dca;
    transform: scale(1.02);
  }
}

.home_01_bg {
  position: relative;
  width: 7rem;
  height: 4.43rem;
  overflow: hidden;

  img {
    width: 100%;
    height: 4.43rem;
  }
}

.home_01_right {
  width: 5rem;
  height: 4.43rem;
  padding-left: 0.5rem;
  background-color: white;
}

.home_01_title {
  width: 3.76rem;
  height: 0.6rem;
  font-size: 0.19rem;
  color: #a35dca;
  text-align: left;
  line-height: 0.6rem;
  font-family: RoBotMedium;
  margin-top: 0.63rem;
}

.home_01_desc {
  width: 3.76rem;
  height: 1.1rem;
  font-size: 0.32rem;
  text-align: left;
  color: black;
  font-family: smallHome;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.home_01_date {
  height: 0.5rem;
  width: 3.76rem;
  font-size: 0.19rem;
  color: #7f7f7f;
  line-height: 0.19rem;
  text-align: left;
  margin-top: 0.63rem;
}

.home_01_arrow {
  width: 0.28rem;
  height: 0.16rem;
  background: url(../../../assets/arrows.png) no-repeat center;
  background-size: cover;
  margin-left: 4.07rem;
}

//
.incline_block_01 {
  position: absolute;
  width: 8rem;
  height: 8rem;
  background-color: black;
  opacity: 0.5;
  left: -8rem;
  top: -5rem;
  display: block;
  transition: 0.1s;
}

// .home_news_01_wrap:hover .incline_block_01 {
//   position: absolute;
//   width: 8rem;
//   height: 16.5rem;
//   background-color: #a35dca;
//   opacity: 0.5;
//   left: -8rem;
//   top: -6.1rem;
//   display: block;
//   animation: funcTranslate linear 0.1s forwards;
// }

.home_news_01_wrap:hover .home_01_desc {
  color: #5a2487;
}

.inner-hero {
  position: absolute;
  right: 0;
  top: 50%;
  width: 9.11rem;
  height: 11.53rem;
  margin-top: -5.76rem;
  background: url('../../../assets/top_cards.png') no-repeat center;
  background-size: cover;
  perspective: 2rem;
  // cursor: pointer;
  transform-style: preserve-3d;
  transform: rotateX(0deg) rotateY(0deg);
  pointer-events: none;
}
