:global .modal-enter {
  background: rgba(0, 0, 0, 0);
}
:global .modal-enter-active {
  background: rgba(0, 0, 0, 0.6);
  transition: all 0.3s;
}
:global .modal-enter-done {
  background: rgba(0, 0, 0, 0.6);
}
:global .modal-exit {
  background: rgba(0, 0, 0, 0.6);
}
:global .modal-exit-active {
  background: rgba(0, 0, 0, 0);
  transition: all 0.3s;
}
:global .modal-enter .modal-content {
  opacity: 0;
  transform: scale(0.6);
}
:global .modal-enter-active .modal-content {
  opacity: 1;
  transform: scale(1);
  transition: all 0.3s;
}
:global .modal-exit .modal-content {
  opacity: 1;
  transform: scale(1);
}
:global .modal-exit-active .modal-content {
  opacity: 0;
  transform: scale(0.6);
  transition: all 0.3s;
}
:global .modal-wrap {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 3000;
}
:global .modal-content {
  position: relative;
  width: 100%;
  height: 100%;
}
.back-close {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.inner-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
.close-tip {
  position: absolute;
  left: 50%;
  bottom: -0.5rem;
  width: 3rem;
  margin-left: -1.5rem;
  text-align: center;
  font-size: 0.24rem;
  color: #fff;
  letter-spacing: 0.02rem;
  z-index: 5;
}
.close-btn {
  position: absolute;
  right: -0.45rem;
  top: -0.45rem;
  width: 0.45rem;
  height: 0.45rem;
  z-index: 5;
  cursor: pointer;
  transition: all 0.3s;
}
.close-btn:before {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  width: 0.3rem;
  height: 0.03rem;
  border-radius: 0.01rem;
  background-color: #fff;
  transform: translate(-50%, -50%) rotate(45deg);
}
.close-btn:after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  width: 0.3rem;
  height: 0.03rem;
  border-radius: 0.01rem;
  background-color: #fff;
  transform: translate(-50%, -50%) rotate(-45deg);
}
.close-btn:hover {
  transform: rotate(180deg);
}
