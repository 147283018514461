.pc-footer {
  position: relative;
  width: 100%;
  height: 4.6rem;
  margin-top: -0.6rem;
  background: url('../../assets/pc_footer.png') no-repeat;
  background-size: 100% 100%;
  background-position: center bottom;
  z-index: 2;
  overflow: hidden;
  font-family: pageHome;
}
.top-icon {
  display: flex;
  width: 100%;
  margin-top: 0.9rem;
  align-items: center;
  justify-content: center;
}
.marvel {
  width: 1.37rem;
  height: 0.55rem;
  background: url('../../assets/logo_marvel.png') no-repeat center;
  background-size: contain;
}
.nvsgames {
  width: 2.31rem;
  height: 0.64rem;
  margin: 0 0.75rem;
  background: url('../../assets/logo_nuverse.png') no-repeat center;
  background-size: contain;
}
.kere {
  width: 2.3rem;
  height: 0.66rem;
  margin: 0 0.75rem;
  background: url('../../assets/kere.png') no-repeat center;
  background-size: contain;
}
.seconddinner {
  width: 1.1rem;
  height: 0.97rem;
  background: url('../../assets/logo_seconddinner.png') no-repeat center;
  background-size: contain;
}
.funtap {
  width: 2.3rem;
  height: 0.63rem;
  margin-left: 0.75rem;
  background: url('../../assets/funtap_logo.png') no-repeat center;
  background-size: contain;
}
.age12 {
  width: 2.5rem;
  height: 0.9rem;
  margin-left: 0.75rem;
  background: url('../../assets/age12.jpg') no-repeat center;
  background-size: contain;
}
.bottom-link {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 1.4rem;
  font-size: 0.24rem;
  font-style: italic;
  cursor: pointer;
  .hover-nav {
    color: #fff;
    text-decoration: none;
  }
  .cookie {
    margin-right: 0.3rem;
  }
}

.faq {
  margin: 0 0.3rem;
}

.hover-nav {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -0.05rem;
    width: 0;
    height: 0.02rem;
    background: linear-gradient(to right, rgba(100, 200, 200, 1), rgba(100, 200, 200, 1)),
      linear-gradient(to right, rgba(255, 0, 0, 1), rgba(255, 0, 180, 1), rgba(0, 100, 200, 1));
    background-size: 0 0.03rem, 100% 0.03rem;
    background-position: 100% 100%, 0 100%;
    background-repeat: no-repeat;
    transition: all 0.3s ease-in-out;
  }
  &:hover::after {
    width: 100%;
    transition: all 0.3s ease-in-out;
  }
}

.privacy-link {
  position: relative;
  left: 50%;
  display: flex;
  width: 200%;
  align-items: center;
  justify-content: center;
  margin-top: 0.2rem;
  // transform: scale(0.6);
  transform: translateX(-50%) scale(0.6);
  font-family: smallHome;
  a {
    margin: 0 0.25rem;
    font-size: 0.24rem;
    color: #5b5b5b;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    text-decoration: none;
    &:hover {
      color: #fff;
      transition: all 0.3s ease-in-out;
    }
  }
}

.marvel-text {
  font-size: 0.24rem;
  color: #5b5b5b;
  text-align: center;
  font-family: smallHome;
  transform: scale(0.6);
  margin-top: 0.1rem;
}

:global #opt-out-button {
  margin-left: 0.3rem;

  .cookie-opt-out-text {
    font-size: 0.24rem;
  }
}