:global #root {
  overflow-x: hidden;
  @font-face {
    font-family: pageHome;
    src: url(../utils/font/CCElephantmenTall_W10_Italic.ttf);
  }
  @font-face {
    font-family: smallHome;
    src: url(../utils/font/Roboto_Regular.ttf);
  }
  @font-face {
    font-family: RoBotMedium;
    src: url(../utils/font/Roboto-Medium.ttf);
  }
  @font-face {
    font-family: RoBotBold;
    src: url(../utils/font/Roboto-Bold.ttf);
  }
  // 葡萄牙西班牙法语专用
  // @font-face {
  //   font-family: font_ptes;
  //   src: url(../utils/font/font_pt_es_fr.otf);
  // }
  // 日语专用
  @font-face {
    font-family: font_ja;
    src: url(../utils/font/font_ja.otf);
  }
  // 泰语专用
  @font-face {
    font-family: font_tl;
    src: url(../utils/font/font_tl.ttf);
  }
  // 韩语专用
  @font-face {
    font-family: font_kr_zw;
    src: url(../utils/font/kr_zw.otf);
  }
  .ft_kr_zw {
    font-family: font_kr_zw !important;
  }
  .ft_italic {
    font-family: pageHome !important;
  }
  .ft_ptes {
    font-family: RoBotMedium !important;
  }
  .ft_ja {
    font-family: font_ja !important;
  }
  .ft_tl {
    font-family: font_tl !important;
  }
}
